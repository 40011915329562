import React from 'react'
import {graphql, Link, navigate} from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import RetryButton from '../components/RetryButton'

import SeminarSummary from '../components/SeminarSummary'
import Questionaire from '../components/Questionaire'


import {
	Breadcrumb,
	Icon,
	Button,
	Container,
	Item,
	Image,
	Header,
	Message,
} from 'semantic-ui-react'
import * as styles from './styles/template.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth,
	confirmationColor,
	backColor
} from '../globalVar'


const formatTools = require('../../lib/formatTools')

const Negosyo = require('../../lib/negosyo')


const paymentform = [
		{
			"fieldlist": [
				{
					"type": "header",
					"label": "Deposit Details",
					"details": [""],
					"otherstext": "",
					"validation": []
				},
				{
					"type": "datetime-local",
					"label": "Date/Time of Deposit",
					"details": [""],
					"otherstext": "",
					"validation": [{ "op": "!=''", "type": "string" }]
				},
				{
					"type": "text",
					"label": "Source (Over the counter, GCash, Maya, etc)",
					"details": [""],
					"otherstext": "",
					"validation": [{ "op": "!=''", "type": "string" }]
				},
				{
					"type": "text",
					"label": "Amount",
					"details": [""],
					"otherstext": "",
					"validation": [{ "op": "", "type": "number" }]
				},
				{
					"type": "image",
					"label": "Receipt (please ensure text and numbers are clear)",
					"details": [""],
					"otherstext": "",
					"validation": [{ "op": "!=''", "type": "string" }]
				}


			]
		}
]


class SeminarApplyPageTemplate extends React.PureComponent {
	state = {
		pageid: 0,
		record: this.props.data.negosyoNow.viewSeminar,
		success: "",
		pageLoading:true,
		error:false,
		submitted: false,
		pageSection:[],
		allowFinalize: false,
		regmode: 0,
		addpaymentmode: false,
		regdetails: [],
		username: ""
	}

	componentDidMount() {
		this.fetchData();
	}

	handleSubmitDone = () => {
		this.setState({
			success:"Thank you. A representative will get in touch with you.",
			pageLoading:false,
			submitted: true
		}, function() {
			document.getElementById('page-table').scrollIntoView({
				behavior: 'smooth'
			});
		});

		/*
		if (this.state.username.length > 0) {
			(async () => {
				const response = await Negosyo.checkSeminarRegistered(this.state.username, this.props.pageContext.url);
				if (response.hasOwnProperty("registered") && response.hasOwnProperty("message")  && response.hasOwnProperty("allowed")  && response.hasOwnProperty("regmode") && response.hasOwnProperty("details")) {
					if (response.registered) {
						navigate('/seminar/resource/'+this.props.pageContext.url);
					} else {
						this.setState({
							regmode: response.regmode,
							addpaymentmode: false,
							submitted: response.registered,
							allowFinalize: response.allowed,
							regdetails: response.details,
							success: (response.allowed===false)?response.message:"Thank you. A representative will get in touch with you.",
							pageLoading:false
						});
					}
				} else {
					this.setState({
						success:"Thank you. A representative will get in touch with you.",
						pageLoading:false,
						submitted: true
					});
				}
			})();
		} else {
			this.setState({
				success:"Thank you. A representative will get in touch with you.",
				pageLoading:false,
				submitted: true
			});
		}
		*/
	}

	addPayment = () => {
		this.setState({
			addpaymentmode: true
		});
	}

	cancelPaymentform = () => {
		if (this.state.regdetails.length > 0) {
			this.setState({
				addpaymentmode: false
			});
		} else {
			navigate('/seminar/'+this.props.pageContext.url);
		}
	}

	returnPage = () => {
		/*
		if (this.state.submitted) {
			navigate('/seminar/resource/'+this.props.pageContext.url);
		} else {
			navigate('/seminar/'+this.props.pageContext.url);
		}
		*/
		navigate('/seminar/'+this.props.pageContext.url);
	}

	fetchData = (retry=false) => {
		const {
			url
		} = this.props.pageContext
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.loadSeminarDetails(url, user.username);
				}).catch((err)=>{
					this.loadSeminarDetails(url, "");
				})
			} else {
				this.loadSeminarDetails(url, "");
			}
		}).catch((err)=>{
			this.loadSeminarDetails(url, "");
		})
	}

	loadSeminarDetails = (url, username) => {
		Negosyo.getSeminarDetails(url)
		.then((data)=>{
			this.setState({
				record:data,
			}, function() {
				Negosyo.getSeminarQuestions(url)
				.then((data)=>{
					this.setState({
						pageSection:data
					}, function () {
						this.handleSignIn(username);
					});
				}).catch((err)=>{
					this.setState({
						error:true,
						pageLoading:false
					})
				})
			})
		}).catch((err)=>{
			this.setState({
				error:true,
				pageLoading:false
			})
		})
	}

	handleSubmit = (submitData) => {
		//console.log("Submit "+username+" "+name+" "+contact+ " "+ email);
		const startdateobj = new Date(this.state.record.datestart);
		const schedulestr = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(startdateobj);

		const finalsubmitData = {
			seminarid: this.state.record.url,
			username: submitData.username,
			fields: [
				{"label":"Seminar Name", "value": this.state.record.name},
				{"label":"Seminar Schedule", "value": schedulestr}
			].concat(submitData.fields)
		};
		this.setState({
			success:"Processing...",
			pageLoading:true
		}, function() {
			Negosyo.registerSeminar(finalsubmitData)
			.then((result)=>{
				if(result.status==="OK") {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
							'event': 'seminar_registration',
							'name': this.state.record.name,
						});
					this.handleSubmitDone();
				}else {
					this.setState({
						success:"Unable to submit. Please try again",
						submitted: false,
						pageLoading:false
					})
				}


			}).catch((err)=>{
				this.setState({
					success:"Unable to submit data.",
					submitted: false,
					pageLoading:false
				})
			})
		})
	}

	handleSignIn = (username) => {
		// Check if already registered
		if (username.length > 0) {
			var needupdate = false;
			if (this.state.username !== username) {
				needupdate = true;
			}
			this.setState({
				username: username
			}, function() {
				(async () => {
					const response = await Negosyo.checkSeminarRegistered(username, this.props.pageContext.url);
					if (response.hasOwnProperty("registered") && response.hasOwnProperty("message")  && response.hasOwnProperty("allowed")  && response.hasOwnProperty("regmode") && response.hasOwnProperty("details")) {
						if (response.regmode === this.state.regmode && needupdate === false) {
							return;
						}
						this.setState({
							addpaymentmode: false,
							regmode: response.regmode,
							submitted: response.registered,
							allowFinalize: response.allowed,
							regdetails: response.details,
							success: (response.allowed===false)?response.message:"",
							pageLoading:false
						});
					} else {
						this.setState({
							regmode: 0,
							addpaymentmode: false,
							submitted: false,
							allowFinalize: true,
							regdetails: [],
							success: "",
							pageLoading:false
						});
					}
				})();
			});
		} else {
			this.setState({
				username: username,
				allowFinalize: true,
				pageLoading:false
			});
		}
	}


	handlePaymentUpload = (submitData) => {
		//console.log("Submit "+username+" "+name+" "+contact+ " "+ email);
		const startdateobj = new Date(this.state.record.datestart);
		const schedulestr = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(startdateobj);

		const finalsubmitData = {
			seminarid: this.state.record.url,
			username: submitData.username,
			fields: [
				{"label":"Seminar Name", "value": this.state.record.name},
				{"label":"Seminar Schedule", "value": schedulestr}
			].concat(submitData.fields)
		};
		this.setState({
			success:"Processing...",
			pageLoading:true
		}, function() {
			document.getElementById('page-table').scrollIntoView({
				behavior: 'smooth'
			});

			(async () => {
				try{
					const result = await Negosyo.paySeminar(finalsubmitData);
					if (result.hasOwnProperty("status")) {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
								'event': 'seminar_payment',
								'name': this.state.record.name,
							});

						// status doesn't matter
						this.setState({
							success:result.message,
							regmode: 4,
							addpaymentmode: false,
							submitted: true,
							pageLoading:false
						}, function() {
							this.handleSignIn(submitData.username);
						});
						return;
					}
				} catch (err) {
				}
				this.setState({
					success:"Upload error, please try a smaller image",
					addpaymentmode: true,
					submitted: false,
					pageLoading:false
				}, function() {
					document.getElementById('page-table').scrollIntoView({
						behavior: 'smooth'
					});
				});
			})();

		})
	}

	resetError = () => {
	}

	render() {
		const {
			record,
			error,
			pageLoading,
			pageSection
		} = this.state
		const {
			location
		} = this.props
		const {
			fetchData
		} = this
		var isavailable = false;
		let schedulestr = ""
		if (record.datestart) {
			const startdateobj = new Date(record.datestart);
			schedulestr = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(startdateobj);
			isavailable = record.available;
			var curdatestr = new Date();
			if (record.datestart <= curdatestr.toISOString().substring(0, 10)) {
				isavailable = false;
			}
		}
		// Debug
		//isavailable = true;

		return (

			<Layout location={location} backButton={true}>
				<Container text className={styles.container}>
					<SEO
						canonicalpath={"/seminar/register/"+record.url}
						title={record===undefined ? "Seminar Registration Form":record.name}
						image={record.mainImageHref}
						twitterCard={"summary"}
						description={record.summary}
						meta={[{name: `robots`, content: `noindex`}]}
					/>
					<Breadcrumb className={styles.desktopOnly}>
						<Breadcrumb.Section content="Home" as={Link} to="/"/>
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={"Seminars"} as={Link} to={"/seminar/"} />
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={record.name+" Registration"} />
					</Breadcrumb>
					<Breadcrumb className={styles.mobileOnly}>
						<Breadcrumb.Section as={Link} to={"/seminar/"} >
							<Icon name="arrow left" />
							{"Seminars"}
						</Breadcrumb.Section>
					</Breadcrumb>
					<br/>


					{(record.url !== "") &&
						<SeminarSummary
							{...record}
							subtitle={"Registration Form"}
							recorddetails={{}}
							hasRegistered={false}
						/>
					}


					{error===true  && (
						<RetryButton
							retryFunc={()=>{
								this.setState({
									error:false,
									pageLoading:true
								})
								fetchData(true)
							}}
							errorMessage={"Unable to load Seminar Registration Form"}
						/>
					)}
					<span id="page-table" className={styles.anchor}/>

					{(this.state.regmode >= 3 && this.state.regmode <= 5)?
					<>
						{(this.state.regmode === 4)&&
							<table border="1" cellPadding={3} cellSpacing={0} style={{borderCollapse:"collapse", width:"100%", textAlign:"center"}}>
								<tr>
									<td>Ref #</td>
									<td>Date</td>
									<td>Amount</td>
									<td>Status</td>
									<td>Notes</td>
								</tr>
								{
									this.state.regdetails.map((paymentdata)=>{
										return <tr>
											<td>{paymentdata.docnum}</td>
											<td>{paymentdata.date}</td>
											<td align={"right"}>{formatTools.currency(paymentdata.amount)}</td>
											<td>{paymentdata.status}</td>
											<td>{paymentdata.notes}</td>
										</tr>

									})
								}
								<tr><td colspan="5">
									<>
										{this.state.regmode === 4 && <div>
											<br/>{this.state.success}<br/><br/>
										</div>}
										Please refresh/come back after a few minutes if you do not see your latest payments

									</>
								</td></tr>
							</table>
						}
						<Message warning className={styles.msg}>
							<Message.Content>
								{(this.state.regmode === 3 || this.state.regmode === 4)&&<div>
									<div className={styles.textalignleft}>
										{record.paymentinstructions.split("\n").map((instructionrow)=>{
											return <>
												{instructionrow}<br/>
											</>
										})}
									</div>
									<br/><br/>
									You may come back to this page any time, just visit the seminar page.
									<br/><br/>
								</div>}

								{(this.state.regmode !== 3 && this.state.addpaymentmode === false)&&
									<>
										{(this.state.success.length > 0 && this.state.regmode !== 4 ) && <div>{this.state.success}</div>}
										<Button
											color={backColor}
											loading={this.state.pageLoading}
											disabled={this.state.pageLoading}
											onClick={this.returnPage}
											className={styles.form}
										>
											Back
										</Button>
										{this.state.regmode === 4 && <>
											&nbsp;&nbsp;&nbsp;

											<Button
												color={confirmationColor}
												loading={this.state.pageLoading}
												disabled={this.state.pageLoading}
												onClick={this.addPayment}
												className={styles.form}
											>
												Add Payment
											</Button>
										</>}
									</>
								}
							</Message.Content>
						</Message>
						{(this.state.regmode === 3 || this.state.addpaymentmode === true)&&
						<>
							<span id="page-top" className={styles.anchor}/>
							<div>
								Please upload the proof of payment and other details using the form below.<br/><br/>
							</div>
							<Questionaire

								questionlist={paymentform}
								requirelogin={true}
								pageLoading={pageLoading}
								customSubmit={this.handlePaymentUpload}
								customSignIn={this.handleSignIn}
								customReturn={this.cancelPaymentform}
							/>
						</>}
					</>
					:
					<>
						{(this.state.allowFinalize && isavailable && this.state.submitted === false) ?
							<>
								<span id="page-top" className={styles.anchor}/>
								<Questionaire
									questionlist={pageSection}
									requirelogin={(record.exclusive || record.fees.length > 0)}
									pageLoading={pageLoading}
									customSubmit={this.handleSubmit}
									customSignIn={this.handleSignIn}
									customReturn={this.returnPage}
								/>
								{this.state.success.length>0 && <div>{this.state.success}</div>}
							</>
						:
							<>
							{this.state.pageLoading?
							<Message warning className={styles.msg}>
								<Message.Content>
									<div>Please wait...</div>
								</Message.Content>
							</Message>
							:
							<Message warning className={styles.msg}>
								<Message.Content>
									{isavailable===false?<>
										<div>Registration is closed</div>
										<Button
											color={confirmationColor}
											loading={this.state.pageLoading}
											disabled={this.state.pageLoading}
											onClick={this.returnPage}
											className={styles.form}
										>
											OK
										</Button>
									</>:<>
										<div>{this.state.success}</div>
										<Button
											color={confirmationColor}
											loading={this.state.pageLoading}
											disabled={this.state.pageLoading}
											onClick={this.returnPage}
											className={styles.form}
										>
											OK
										</Button>

									</>}
								</Message.Content>
							</Message>
							}
							</>
						}
					</>
					}

				</Container>
			</Layout>
		)
	}
}

export default SeminarApplyPageTemplate


export const data = graphql`
	query SeminarRegisterQuery($url:String!) {
		negosyoNow {
			viewSeminar( url: $url ) {
				id
				name
				summary
				url
				mainImageHref
				slug
				organizationName
				organizationUrl
				sku
				datestart
				exclusive
				available
				link
				linkdetail
				fees
				paymentinstructions
			}
		}
	}
`

